<style lang='postcss'>
.not-robot {
  @apply flex flex-col items-center text-center pb-10;
  &__title {
    @apply font-sb_sans_caps;
    font-size: 24px;
    line-height: 140%;
  }
}
</style>

<template>
  <div class="not-robot">
    <icon-not-robot class="mb-3" />
    <h3 class="not-robot__title mb-9">
      Докажи, что ты не робот<br />
      Убей 4 инопланетян
    </h3>
    <div v-html="captcha_html"></div>
    <transition enter-active-class="animate__animated animate__fadeIn">
      <button v-if="captchaDone" class="sb-btn mt-9" @click="handleContinue">
        Продолжить
      </button>
    </transition>
  </div>
</template>

<script>
import IconNotRobot from "./Icons/IconNotRobot.vue";
export default {
  name: "DoomCaptcha",
  components: { IconNotRobot },
  data() {
    return {
      captchaDone: false,
      captcha_html:
        '<iframe id="doom_captcha" src="https://vivirenremoto.github.io/doomcaptcha/captcha.html?version=18&countdown=on&enemies=4" style="width:300px;height:150px;border:2px black solid;"></iframe>',
    };
  },
  props: {},
  created() {},
  mounted() {
    window.addEventListener(
      "message",
      (e) => {
        if (e.origin.indexOf("vivirenremoto.github.io") > -1) {
          this.captchaDone = true;
        }
      },
      false
    );
  },
  methods: {
    handleContinue() {
      this.$router.push({ name: "Stages" });
      window.scrollTo(0, 0);
    },
  },
  computed: {},
};
</script>
