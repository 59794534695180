<style lang='postcss'>
.level-complete {
  @apply text-center;
  color: #ffffff;
  &__title {
    @apply mt-4 font-sb_sans_caps;
    font-size: 46px;
    line-height: 110%;
  }
  &__subtitle {
    @apply mt-4 font-sb_sans_display;
    font-size: 24px;
    line-height: 110%;
  }
}
</style>

<template>
  <div class="level-complete">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <doom-captcha v-if="showCaptcha" />
      <div v-else>
        <div class="flex justify-center">
          <icon-flash v-for="i in 3" :key="i" class="mx-1" />
        </div>
        <div v-if="title" class="level-complete__title" v-html="title" />
        <slot></slot>
        <div
          v-if="subtitle"
          class="level-complete__subtitle"
          v-html="subtitle"
        />
        <button class="sb-btn mt-9 ym-continue" @click="handleContinue">Продолжить</button>
      </div>
    </transition>
  </div>
</template>

<script>
import DoomCaptcha from "./DoomCaptcha.vue";
import IconFlash from "./Icons/IconFlash.vue";
export default {
  name: "LevelComplete",
  components: { IconFlash, DoomCaptcha },
  data() {
    return {
      showCaptcha: false,
    };
  },
  props: {
    title: { type: String },
    subtitle: { type: String },
  },
  created() {},
  mounted() {},
  methods: {
    handleContinue() {
      if (this.currentUser) {
        this.$router.push({ name: "Stages" });
        window.scrollTo(0, 0);
      } else {
        this.showCaptcha = true;
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    },
  },
};
</script>
