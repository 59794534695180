<template>
  <svg
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M56.9827 22.7579C53.8294 19.0619 49.0673 16.9421 43.9189 16.9421H38.9114C36.2211 16.9421 33.736 17.4833 31.5249 18.5517C25.6797 21.3736 22.0487 27.1298 22.0487 33.5757C22.0487 35.5599 21.16 37.4103 19.61 38.6497L17.9238 39.9984L18.3001 40.586C20.8921 44.6288 22.8144 49.0356 24.0136 53.6861L24.2552 54.6221C24.6717 56.2356 25.7076 57.5447 27.0965 58.2144C28.6356 58.9584 30.4464 58.9189 31.9514 58.1037L43.3321 51.681L42.5711 50.3323L31.2028 56.7481C30.1468 57.321 28.8632 57.3473 27.7692 56.8201C26.7907 56.3486 26.0567 55.4064 25.754 54.235L25.5125 53.299C25.4482 53.0497 25.3739 52.8036 25.3058 52.555H27.4681V51.0067H24.8513C23.6792 47.2603 22.0487 43.682 19.9754 40.3406L20.5762 39.8598C22.4962 38.3238 23.5971 36.0338 23.5971 33.5757C23.5971 27.729 26.8929 22.507 32.1976 19.946C34.1974 18.9806 36.4565 18.4905 38.9114 18.4905H43.9189C44.114 18.4905 44.3068 18.499 44.5003 18.5052V20.8131H46.0487V18.6299C49.9065 19.1192 53.3842 20.9261 55.8043 23.7628C58.5821 27.019 59.7233 31.2477 59.018 35.6707C58.7099 37.6061 58.0418 39.4232 57.0338 41.0691C52.8864 47.8363 50.6939 55.2198 50.6939 62.4206V63.3937H46.0487V48.6841H44.5003V63.3937H36.7584V57.2002H35.21V64.9421H52.2423V62.4206C52.2423 55.5055 54.3558 48.4023 58.3538 41.8774C59.4686 40.0565 60.2072 38.0513 60.5471 35.9137C61.3251 31.0363 60.0593 26.3641 56.9827 22.7579Z"
        fill="#51D55E"
      />
      <path
        d="M30.5651 36.2969H25.9199V37.8453H30.5651V36.2969Z"
        fill="#51D55E"
      />
      <path
        d="M49.9202 33.2003C49.9202 30.6385 47.8369 28.5552 45.275 28.5552C42.7132 28.5552 40.6299 30.6385 40.6299 33.2003C40.6299 35.7621 42.7132 37.8455 45.275 37.8455C47.8369 37.8455 49.9202 35.7621 49.9202 33.2003ZM45.275 36.2971C43.5672 36.2971 42.1783 34.9082 42.1783 33.2003C42.1783 31.4925 43.5672 30.1036 45.275 30.1036C46.9829 30.1036 48.3718 31.4925 48.3718 33.2003C48.3718 34.9082 46.9829 36.2971 45.275 36.2971Z"
        fill="#51D55E"
      />
      <path
        d="M41.6562 41.7488C41.7003 41.7666 41.7444 41.7852 41.7886 41.803L42.3475 44.0389H48.202L48.7609 41.803C48.8059 41.7852 48.8492 41.7666 48.8933 41.7488L50.8698 42.9333L55.0087 38.7945L53.8234 36.8179C53.842 36.773 53.8606 36.7297 53.8784 36.6856L56.1135 36.1274V30.2729L53.8784 29.7139C53.8079 29.5405 53.7313 29.3679 53.65 29.1968L52.2526 29.8634C52.371 30.1111 52.4763 30.3635 52.5677 30.6236L52.7062 31.0169L54.5651 31.4822V34.9181L52.7062 35.3834L52.5677 35.7767C52.4763 36.0368 52.371 36.2892 52.2526 36.5354L52.0729 36.9101L53.0585 38.5545L50.6291 40.9847L48.9855 39.9991L48.61 40.1787C48.3638 40.2964 48.1098 40.4017 47.8497 40.4938L47.4572 40.6324L46.9927 42.4905H43.5568L43.0923 40.6324L42.7006 40.4938C42.4404 40.4017 42.1865 40.2964 41.9403 40.1787L41.5648 39.9991L39.9212 40.9847L37.0644 38.1279L35.9697 39.2226L39.6812 42.9341L41.6562 41.7488Z"
        fill="#51D55E"
      />
      <path
        d="M34.4355 30.2731V36.1275L36.6706 36.6865C36.7411 36.8607 36.8177 37.0333 36.8998 37.2036L38.2964 36.5355C38.1788 36.2893 38.0727 36.0362 37.9814 35.7768L37.8428 35.3835L35.9839 34.9182V31.4823L37.8428 31.0171L37.9814 30.6238C38.0727 30.3636 38.178 30.1112 38.2964 29.8635L38.4753 29.4888L37.4897 27.846L39.9192 25.4158L41.5628 26.4013L41.9383 26.2217C42.1844 26.104 42.4384 25.9987 42.6985 25.9066L43.0903 25.768L43.5548 23.91H46.9906L47.4552 25.768L47.8469 25.9066C48.107 25.9987 48.361 26.104 48.6072 26.2217L48.9826 26.4013L50.6262 25.4158L53.483 28.2725L54.5777 27.1778L50.8663 23.4663L48.8897 24.6509C48.8456 24.6331 48.8015 24.6145 48.7573 24.5967L48.2015 22.3616H42.347L41.7881 24.5974C41.7432 24.6152 41.6998 24.6338 41.6557 24.6516L39.6792 23.4671L35.5403 27.606L36.7256 29.5825C36.707 29.6266 36.6884 29.6707 36.6706 29.7149L34.4355 30.2731Z"
        fill="#51D55E"
      />
      <path
        d="M46.0494 25.4583H44.501V27.0066H46.0494V25.4583Z"
        fill="#51D55E"
      />
      <path
        d="M41.4427 28.2739L40.3477 27.1792L39.2529 28.2742L40.348 29.3689L41.4427 28.2739Z"
        fill="#51D55E"
      />
      <path
        d="M39.0806 32.426H37.5322V33.9744H39.0806V32.426Z"
        fill="#51D55E"
      />
      <path
        d="M40.3474 39.2178L41.4424 38.123L40.3477 37.028L39.2527 38.1227L40.3474 39.2178Z"
        fill="#51D55E"
      />
      <path
        d="M46.0494 39.3938H44.501V40.9422H46.0494V39.3938Z"
        fill="#51D55E"
      />
      <path
        d="M51.2933 38.1294L50.1982 37.0347L49.1035 38.1297L50.1986 39.2244L51.2933 38.1294Z"
        fill="#51D55E"
      />
      <path
        d="M53.0162 32.426H51.4678V33.9744H53.0162V32.426Z"
        fill="#51D55E"
      />
      <path
        d="M51.293 28.2771L50.1982 27.1821L49.1032 28.2768L50.1979 29.3719L51.293 28.2771Z"
        fill="#51D55E"
      />
      <path
        d="M46.0494 45.5874H44.501V47.1358H46.0494V45.5874Z"
        fill="#51D55E"
      />
    </g>
    <circle
      cx="40.3477"
      cy="40.3594"
      r="38.3333"
      stroke="#6A2BEF"
      stroke-width="3.33333"
    />
    <path
      d="M12.0137 67.026L66.793 12.9131"
      stroke="#6A2BEF"
      stroke-width="4"
    />
    <defs>
      <clipPath id="clip0">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="translate(15.3467 16.9421)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconNotRobot",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
