<template>
  <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.6764 17.1819C27.6764 20.8298 24.7179 23.7861 21.0701 23.7861C17.4222 23.7861 14.4658 20.8298 14.4658 17.1819C14.4658 13.5319 17.4222 10.5756 21.0701 10.5756C24.7179 10.5756 27.6764 13.5319 27.6764 17.1819Z"
      stroke="currentColor"
      stroke-width="2.08929"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.0673 32.4375C29.0233 32.4375 36.3003 26.717 40.3974 17.1815C36.3003 7.64599 29.0233 1.92553 21.0673 1.92553H21.0757C13.1197 1.92553 5.84269 7.64599 1.74561 17.1815C5.84269 26.717 13.1197 32.4375 21.0757 32.4375H21.0673Z"
      stroke="currentColor"
      stroke-width="2.08929"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEye",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
