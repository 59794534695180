<template>
  <svg
    width="41"
    height="42"
    viewBox="0 0 41 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1793 3.47473L5.11816 23.9481H20.4732L18.7671 37.597L35.8282 17.1237H20.4732L22.1793 3.47473Z"
      stroke="#FFD850"
      stroke-width="1.56819"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconFlash",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
