<style lang="postcss">
.game-password {
  @apply relative;
  &__input {
    @apply relative;
    height: 63px;
  }

  &__validation {
    margin-top: 28px;
    display: flex;
    flex-direction: column-reverse;
  }

  &__check {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &--valid {
      color: #66de52;
    }

    &--invalid {
      color: #ff5656;
    }
  }

  &__check-text {
    font-family: SB Sans Text;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 140%;
    margin-left: 6px;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}
.landing-btn {
  &__blink {
    @apply fixed md:absolute font-sb_sans_text px-3 w-full top-auto md:top-14px bottom-60px md:bottom-auto md:left-auto md:right-15px w-auto;
    border-radius: 4px;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    background: #2dff81;
    --height: 31px;
    height: var(--height);
    line-height: var(--height);
    animation: blink 1500ms infinite;
    left: calc(50% - 281px / 2);
  }
}

@keyframes blink {
  0% {
    background-color: #2dff81;
    color: #000000;
  }
  50% {
    background-color: transparent;
    color: #2dff81;
  }
  100% {
    background-color: #2dff81;
    color: #000000;
  }
}
.game-password-progress {
  position: absolute;
  bottom: calc(100% + 20px);
}
.input-stub {
  @apply font-chava px-6 w-auto absolute left-0 bottom-2px;
  font-size: 32px;
  line-height: 63px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}
</style>

<template>
  <div class="game-password">
    <transition leave-active-class="animate__animated animate__fadeOut">
      <div v-if="showInputStub">
        <div class="input-stub">Пароль</div>
      </div>
    </transition>

    <transition enter-active-class="animate__animated animate__fadeIn">
      <progress-line
        v-if="!showInputPreview"
        :max-value="maxIssues"
        :value="checkedIssues"
        class="game-password-progress"
      />
    </transition>

    <div class="game-password__input">
      <GameInput
        :value="password"
        :passwordType="passwordType"
        @input="setPassword"
      >
        <transition
          v-if="showRight"
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in"
        >
          <template v-if="showInputPreview">
            <span class="landing-btn__blink">Для начала введи 3 символа</span>
          </template>
          <template v-else>
            <div class="flex items-center">
              <input-emoji-picker class="mr-2" @append="addToPassword" />
              <button class="sb-btn---icon mr-5" @click="togglePassword">
                <icon-eye v-if="showPassword" /><icon-eye-stroke v-else />
              </button>
              <GameTimer :isCounterActive="startTimer" class="mr-4" />
            </div>
          </template>
        </transition>
      </GameInput>
    </div>
    <transition-group
      v-if="checks.length"
      name="list"
      tag="div"
      class="game-password__validation"
    >
      <div
        v-for="c in checks"
        :key="c.text"
        class="game-password__check"
        :class="{
          'game-password__check--valid': c.valid,
          'game-password__check--invalid': !c.valid,
        }"
      >
        <div class="game-password__check-icon">
          <IconCheckCircle v-if="c.valid" />
          <IconCrossCircle v-else />
        </div>
        <div class="game-password__check-text">
          {{ c.text }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import GameInput from '@/components/UI/GameInput.vue';

import IconCheckCircle from '@/components/Icons/IconCheckCircle';
import IconCrossCircle from '@/components/Icons/IconCrossCircle';
import GameTimer from '@/components/UI/GameTimer.vue';
import IconEyeStroke from '../Icons/IconEyeStroke.vue';
import IconEye from '../Icons/IconEye.vue';

import { io } from 'socket.io-client';
import ProgressLine from './ProgressLine.vue';
import IconSmile from '../Icons/IconSmile.vue';
import InputEmojiPicker from '../InputEmojiPicker.vue';

export default {
  name: 'GamePassword',
  components: {
    GameInput,
    IconCheckCircle,
    IconCrossCircle,
    GameTimer,
    IconEyeStroke,
    IconEye,
    ProgressLine,
    IconSmile,
    InputEmojiPicker,
  },
  data() {
    return {
      password: '',
      socket: null,
      checks: [],
      valid: false,
      showPassword: false,
      startTimer: false,
      showInputPreview: true,
      maxIssues: 0,
      showInputStub: true,
      showRight: false,
    };
  },
  props: {},
  created() {
    this.password = ''
    this.socket = io(process.env.VUE_APP_SERVER_URL);
    if (this.currentUser) {
      this.showInputPreview = false;
    }
  },
  mounted() {
    if (this.isSecondLevel) {
      this.sendAnswerSecondLevel('');
    }
    this.getNumberOfQuestions();
    if (this.currentUser) {
      this.showRight = true;
    } else {
      setTimeout(() => {
        this.showRight = true;
      }, 2000);
    }
    if (this.currentUser) {
      this.showInputStub = false;
    }
  },
  methods: {
    getNumberOfQuestions() {
      this.socket.emit(
        'get_length',
        { user_id: this.currentUser },
        (response) => {
          this.maxIssues = response.result;
        }
      );
    },
    sendAnswer(channel, payload) {
      this.socket.emit(channel, payload, (response) => {
        this.checks = response.result.checks;
        this.valid = response.result.valid;
      });
    },
    sendAnswerSecondLevel(password) {
      this.sendAnswer('second_password', {
        password: password,
        user_id: +this.currentUser.id,
      });
    },
    sendAnswerFirstLevel(password) {
      this.sendAnswer('first_password', { password: password });
    },
    setPassword(e) {
      this.password = e;
    },
    addToPassword(val) {
      this.password += val;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  computed: {
    isSecondLevel() {
      return this.currentUser && this.$route.name !== 'Results';
    },
    reversedChecks() {
      return [...this.checks].reverse();
    },
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    passwordType() {
      return this.showPassword ? 'text' : 'password';
    },
    checkedIssues() {
      if (this.valid) {
        return this.checks.length;
      }
      return this.checks.length === 0 ? 0 : this.checks.length - 1;
    },
    firstLevelStart() {
      return this.$store.state.game.firstLevelStart;
    },
  },
  watch: {
    valid(val) {
      if (val) {
        this.$store.dispatch('game/setMarquee', {
          text: `Ты справился!`,
          type: 'success',
        });
        if (this.isSecondLevel) {
          this.$store.dispatch('game/setSecondLevelComplete');
        } else {
          this.$store.dispatch('game/setFirstLevelComplete');
          this.$store.dispatch('game/setFirstLevelResult');
          this.$emit('next');
        }
      }
    },
    password(val) {
      if (!this.firstLevelStart) {
        this.$store.dispatch('game/setFirstLevelStart');
      }
      if (this.currentUser) {
        if (val.length >= 1) {
          this.startTimer = true;
        }
      } else {
        if (val.length >= 3) {
          this.showInputPreview = false;
          this.startTimer = true;
          this.$emit('addValue');
        }
        if (val.length >= 1) {
          this.showInputStub = false;
        }
      }

      if (this.isSecondLevel) {
        this.sendAnswerSecondLevel(val);
      } else {
        if (val.length > 2) {
          this.sendAnswerFirstLevel(val);
        }
      }
    },
    showInputPreview(val) {
      if (!val) {
        this.$nextTick(function() {
          setTimeout(this.addEyeListeners, 2000);
        });
      }
    },
  },
  destroyed() {},
};
</script>
