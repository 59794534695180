<style lang='postcss'>
.game-timer {
  @apply font-sb_sans_text font-bold rounded w-max flex items-center justify-center;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  background: #ffc700;
  --height: 32px;
  height: var(--height);
  min-width: 76px;
}
</style>

<template>
  <div class="game-timer">
    <g-digital-counter
      :isCounterActive="isCounterActive"
    />
  </div>
</template>

<script>
export default {
  name: "GameTimer",
  components: { gDigitalCounter: () => import("@geeckocom/g-digital-counter") },
  data() {
    return {};
  },
  props: {
    isCounterActive: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
