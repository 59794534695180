<template>
  <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.3343 22.9304C15.1364 21.7345 14.4072 20.1095 14.4072 18.2866C14.4072 14.6345 17.3489 11.6908 20.9989 11.6908C22.8051 11.6908 24.4676 12.422 25.6447 13.6179"
      stroke="currentColor"
      stroke-width="2.08333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.468 19.4556C26.9847 22.1431 24.868 24.2639 22.1826 24.7514"
      stroke="currentColor"
      stroke-width="2.08333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.86344 29.4002C6.55719 26.8043 3.75719 23.0127 1.72803 18.2856C3.77803 13.5377 6.59678 9.72515 9.92386 7.10848C13.2301 4.49182 17.0447 3.07098 20.9989 3.07098C24.9759 3.07098 28.7884 4.51265 32.1155 7.14807"
      stroke="currentColor"
      stroke-width="2.08333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.5158 11.7304C37.9491 13.6346 39.2095 15.8325 40.27 18.2846C36.172 27.7783 28.9304 33.4971 20.9991 33.4971C19.2012 33.4971 17.4283 33.2054 15.7241 32.6367"
      stroke="currentColor"
      stroke-width="2.08333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.4309 1.85287L4.56836 34.7154"
      stroke="currentColor"
      stroke-width="2.08333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEyeStroke",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
