<style lang='postcss'>
.progress-line {
  @apply w-full text-left;
  color: rgba(168, 179, 221, 0.7);
  &__title {
    @apply font-sb_sans_text mb-2;
    font-size: 15px;
    line-height: 24px;
  }
  &__stripe {
    @apply w-full relative;
    --width;
    height: 4px;
    background: rgba(175, 182, 201, 0.3);
    &:after {
      content: "";
      @apply absolute left-0 bottom-0 h-full;
      width: var(--width);
      background: #C2CBE0;
    }
  }
  &.done {
    color: #66DE52;
    &__stripe {
      &:after {
        background: #66DE52;
      }
    }
  }
}
</style>

<template>
  <div class="progress-line" :class="{ done: value === maxValue }">
    <div class="progress-line__title">
      {{ `${value} из ${maxValue} условий` }}
    </div>
    <div
      class="progress-line__stripe"
      :style="{ '--width': `${(value / maxValue) * 100}%` }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PropgressLine",
  components: {},
  data() {
    return {};
  },
  props: {
    maxValue: { type: [String, Number] },
    value: { type: [String, Number], default: 0 },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
