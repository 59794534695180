<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M12 21.9997C17.5228 21.9997 22 17.5226 22 11.9997C22 6.47688 17.5228 1.99973 12 1.99973C6.47715 1.99973 2 6.47688 2 11.9997C2 17.5226 6.47715 21.9997 12 21.9997Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 13.9997C8 13.9997 9.5 15.9997 12 15.9997C14.5 15.9997 16 13.9997 16 13.9997"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 8.99973H9.01"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 8.99973H15.01"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconSmile",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
