<style lang='postcss'>
.wrapper {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  cursor: pointer;
}

.emoji-picker {
  position: absolute;
  top: calc(100% + 10px);
  left: -50px;
  z-index: 999;
  width: 200px;
  height: 250px;
  overflow: scroll;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #000000;
  border: 1px solid #C2CBE0;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  @apply font-sb_sans_text mb-2;
  flex: 1;
  border-radius: 4px;
  padding: 2px 8px;
  outline: none;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 14px;
}
.emoji-picker h5 {
  @apply font-chava;
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: rgba(236, 236, 236, 0.5);
  cursor: pointer;
}
</style>

<template>
  <div class="wrapper">
    <emoji-picker :search="search" @emoji="append">
      <div
        slot="emoji-invoker"
        slot-scope="{ events: { click: clickEvent } }"
        class="emoji-invoker"
        @click.stop="clickEvent"
      >
        <icon-smile />
      </div>
      <div slot="emoji-picker" slot-scope="{ emojis, insert }">
        <div
          class="emoji-picker"
        >
          <div class="emoji-picker__search">
            <input v-model="search" v-focus type="text" />
          </div>
          <div>
            <div v-for="(emojiGroup, category) in emojis" :key="category">
              <h5>{{ category }}</h5>
              <div class="emojis">
                <span
                  v-for="(emoji, emojiName) in emojiGroup"
                  :key="emojiName"
                  :title="emojiName"
                  @click="insert(emoji)"
                >{{ emoji }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </emoji-picker>
  </div>
</template>

<script>
import EmojiPicker from "vue-emoji-picker";
import IconSmile from "./Icons/IconSmile.vue";

export default {
  name: "InputEmojiPicker",
  components: { EmojiPicker, IconSmile },
  data() {
    return {
      search: "",
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    append(emoji) {
      this.$emit('append', emoji)
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  computed: {},
};
</script>
