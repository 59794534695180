<style lang="postcss">
.game-input {
  @apply h-full flex w-full box-border;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  justify-content: space-between;
  & input {
    padding: 16px;
  }

  &__left {
    @apply flex-grow flex;
  }
  &__right {
    @apply flex items-center;
    & .sb-btn---icon {
      color: rgba(255, 255, 255, 0.3);
      & svg {
        width: 20px;
      }
    }
  }

  &__inner {
    @apply font-sb_sans_caps;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    background-color: rgba(0, 0, 0, 0);
    /* identical to box height, or 28px */

    color: #ffffff;

    &:focus {
      outline: none;
    }
  }
}
</style>

<template>
  <div class="game-input">
    <div class="game-input__left">
      <input
        ref="gameInput"
        :type="passwordType"
        class="game-input__inner"
        autocomplete="off"
        :value="value"
        @input="input"
      />
    </div>
    <div class="game-input__right">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    value: String,
    passwordType: {
      type: String,
      default: "password",
      validator: function (value) {
        return ["password", "text"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    input(e) {
      this.$emit("input", e.target.value);
    },
  },
  mounted() {
    this.$refs["gameInput"].focus();
  },
  computed: {},
  watch: {},
};
</script>